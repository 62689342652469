import React, { useState, useEffect} from "react";
import ReactGA from 'react-ga4';
import { Toast } from "react-bootstrap";

const RequestQuote = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/projects", title: "Projects" });
}, [])
  
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [message, setMessage] = useState('')
  const [showToast, setToast] = useState('')
  const [quantity, setQuantity] = useState('')

  const isFormReady = () => {
    return name?.length > 2 && phone?.length > 9 && address?.length > 3
  }

  const clearForm = () => {
    setName('')
    setPhone('')
    setEmail('')
    setAddress('')
    setMessage('')
    return
  }

  const submitRequest = async () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "REQUEST_QUOTE_SUBMIT"
    });
    const response = await fetch('https://formsubmit.co/ajax/59077dba8be1ce7f991dfec17e93c3a6', {
      method: 'POST',
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        phone,
        message,
        email,
        address,
        quantity
      })
    })
    if (response.status === 200) {
      setToast(true)
    }
    clearForm()
  }

  return (
    <div id="contact" className="container-xxl py-5 nav-section">
      <div className="container">
        <div className="text-center wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title text-center text-primary text-uppercase">
            Get in touch
          </h6>
        </div>
        <div className="container-sm">
          <div className="text-center mt-2 mb-2">
            <h2 className="">Let us reach out to <span className="text-primary text-uppercase">YOU</span></h2>
          </div>
          <div className="d-flex justify-content-evenly pt-2">
            <div className="col-5">
              <label className="">Name <span style={{color: 'red'}}>*</span></label> <br />
              <input type="text" name="" value={name} onChange={(e) => {setName(e.target.value)}} id="" className="form-control"/>
            </div>
            <div className="col-5">
              <label className="">Phone <span style={{color: 'red'}}>*</span></label> <br /> 
              <input type="number" name="" id="" value={phone} onChange={e => setPhone(e.target.value)} className="form-control"/>
            </div>
          </div>
          <div className="d-flex justify-content-evenly pt-2 mt-2">
            <div className="col-5">
                <label className="">Email <span style={{color: 'red'}}>*</span></label> <br />
                <input type="email" name="" id="" value={email} onChange={e => setEmail(e.target.value)}  className="form-control"/>
              </div>
              <div className="col-5">
                <label className="">Address <span style={{color: 'red'}}>*</span></label> <br />
                <input type="text" name="" id="" value={address} onChange={e => setAddress(e.target.value)}  className="form-control"/>
              </div>
          </div>
          <div className="d-flex justify-content-evenly pt-2 mt-2">
            <div className="col-5">
                <label className="">Message</label> <br />
                <input type="text" name="" id="" value={message} onChange={e => setMessage(e.target.value)}  className="form-control"/>
            </div>
            <div className="col-5">
              <label htmlFor="">Approx Quantity Required</label>
              <input type="text" value={quantity} onChange={e => setQuantity(e.target.value)} className="form-control"/>
            </div>
          </div>
          <div className="text-center mt-3">
            <button disabled={!isFormReady()} className="btn btn-primary" onClick={submitRequest}>Submit</button>
          </div>

        </div>
      </div>
      <Toast onClose={() => setToast(false)} show={showToast} animation={true}  delay={3000} autohide>
        <Toast.Header>Response Recorded</Toast.Header>
        <Toast.Body>Thank you for reaching out. Our team will be in touch with you shortly</Toast.Body>
      </Toast>
    </div>
  )
}

export default RequestQuote