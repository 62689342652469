import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Services = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/services", title: "Services" });
}, [])
  return (
    <div className="container-xxl py-5 nav-section" id="services">
      <div className="container">
        <div className="text-center wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title text-center text-primary text-uppercase">
            Why Choose us
          </h6>
          <h1 className="mb-5">
             Our {" "}
            <span className="text-primary text-uppercase">Competencies</span>
          </h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
            <div className="service-item rounded">
              <div className="service-icon bg-transparent border rounded p-1">
                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                  <i className="fa fas fa-medal fa-2x text-primary" />
                </div>
              </div>
              <div className='service-content-wrapper'>
                <h5 className="mb-3 fw-semi-bold ">Superior Raw Materials</h5>
                <p className="text-body font-15 mb-0 fw-bold">
                  The cement, admixtures and stone aggregates that we use are best in class and are constantly tested in our labs to confirm adherence to strict quality standards.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
            <div className="service-item rounded">
              <div className="service-icon bg-transparent border rounded p-1">
                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                  <i className="fa fa-tag fa-2x text-primary" />
                </div>
              </div>
              <div className='service-content-wrapper'>
                <h5 className="mb-3 fw-semi-bold ">Transparent Pricing</h5>
                <p className="text-body font-15 mb-0 fw-bold">
                  Here at GMN concrete we make sure you find the pricing information
                  easily, so that you can plan your project costing more efficiently and also make the best
                  choice for the long-term strength of the structure
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
            <div className="service-item rounded" href="#services">
              <div className="service-icon bg-transparent border rounded p-1">
                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                  <i className="fa fa-thumbs-up fa-2x text-primary" />
                </div>
              </div>
              <div className='service-content-wrapper'>
                <h5 className="mb-3 fw-semi-bold ">Unmatched Quality</h5>
                <p className="text-body  font-15 mb-0 fw-bold">
                  Whether it’s the quality of concrete or the quality of service, we do
                  not compromise on any standards to make sure our customers are delighted and at peace
                  of mind when their requirements are being fulfilled by us
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
            <div className="service-item rounded" >
              <div className="service-icon bg-transparent border rounded p-1">
                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                  <i className="fa fa-wrench fa-2x text-primary" />
                </div>
              </div>
              <div className='service-content-wrapper'>
                <h5 className="mb-3 fw-semi-bold">Latest Machinery</h5>
                <p className="text-body font-15 mb-0 fw-bold">
                  All your concrete requirements are fulfilled from our state-of-the-art
                  cluster of machinery, from our Concrete Batching Plant to our fleet of Pumps and Transit
                  Mixers
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded" href="#service">
              <div className="service-icon bg-transparent border rounded p-1">
                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                  <i className="fa fa-clock fa-2x text-primary" />
                </div>
              </div>
              <div className='service-content-wrapper'>
                <h5 className="mb-3 fw-semi-bold ">On-Time, Every Time</h5>
                <p className="text-body font-15 mb-0 fw-bold">
                  Our GPS enabled fleet of Concrete Pumps and Transit Mixers
                  allow real time tracking of your concrete when it’s out for supply.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay="0.6s">
            <div className="service-item rounded">
              <div className="service-icon bg-transparent border rounded p-1">
                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                  <i className="fa fa-calendar-check fa-2x text-primary" />
                </div>
              </div>
              <div className='service-content-wrapper'>
                <h5 className="mb-3 fw-semi-bold">Flexible Schedule</h5>
                <p className="text-body font-15 mb-0 fw-bold">
                  Whether your project requires round-the-clock work to meet a strict
                  deadline, or you’re heading up a project which needs to be carried out at night, GMN
                  Concrete has got you covered
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services