import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import img1 from '../assets/gmn-img1.webp';
import img2 from '../assets/gmn-img2.webp';
import img3 from '../assets/gmn-img3.webp';
import img4 from '../assets/gmn-img4.webp';


const AboutUs = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/about", title: "About Us" });
    }, [])

    const triggerAnalytics = () => {
        ReactGA.event({
          category: "Button",
          action: "Click",
          label: "EXPLORE_MORE"
        });
      }

    return (
        <div id="aboutus" className='nav-section'>  {/* About Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h6 className="section-title text-start text-primary text-uppercase">
                                About Us
                            </h6>
                            <h1 className="mb-4">
                                Welcome to{" "}
                                <span className="text-primary text-uppercase font-arial">GMN Concrete</span>
                            </h1>
                            <p className="mb-4">
                                GMN Concrete is a brand owned and operated by Rohith Builders,
                                specialized in production of high-quality Ready-Mix Concrete. We
                                cater to residential, commercial and industrial projects of all sizes.
                                With state-of-the-art machinery and experts constantly controlling
                                and maintaining the quality, we give you peace of mind by executing
                                all your dream projects on time, every time with an unmatched
                                quality. We currently are located in Nelamangala and cater to
                                projects in North, West and Central Bangalore.
                            </p>
                            {/* <div className="row g-3 pb-4">
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-hotel fa-2x text-primary mb-2" />
                                            <h2 className="mb-1" data-toggle="counter-up">
                                                1234
                                            </h2>
                                            <p className="mb-0">Rooms</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-users-cog fa-2x text-primary mb-2" />
                                            <h2 className="mb-1" data-toggle="counter-up">
                                                1234
                                            </h2>
                                            <p className="mb-0">Staffs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-users fa-2x text-primary mb-2" />
                                            <h2 className="mb-1" data-toggle="counter-up">
                                                1234
                                            </h2>
                                            <p className="mb-0">Clients</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <a className="btn btn-primary py-3 px-5 mt-2" href="#services" onClick={triggerAnalytics}>
                                Explore More
                            </a>
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-end">
                                    <img
                                        className="img-fluid rounded w-75 wow animate__animated animate__zoomIn"
                                        data-wow-delay="0.1s"
                                        src={img1}
                                        alt="gmn-img"
                                        style={{ marginTop: "25%" }}
                                    />
                                </div>
                                <div className="col-6 text-start">
                                    <img
                                        className="img-fluid rounded w-100 wow animate__animated animate__zoomIn"
                                        data-wow-delay="0.3s"
                                        src={img2}
                                        alt="gmn-img"
                                    />
                                </div>
                                <div className="col-6 text-end">
                                    <img
                                        className="img-fluid rounded w-50 wow animate__animated animate__zoomIn"
                                        data-wow-delay="0.5s"
                                        src={img3}
                                        alt="gmn-img"
                                    />
                                </div>
                                <div className="col-6 text-start">
                                    <img
                                        className="img-fluid rounded w-75 wow animate__animated animate__zoomIn"
                                        data-wow-delay="0.7s"
                                        src={img4}
                                        alt="gmn-img"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}</div>
    )
}

export default AboutUs