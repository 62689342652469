import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import '../App.scss';
import concrete_calculator from '../assets/concrete-calculator.png'

const ConcreteCalculator = ({ volume, setvolume }) => {
    const [length, setlength] = useState(null);
    const [width, setwidth] = useState(null);
    const [depth, setdepth] = useState(null);
    const [lengthUnit, setlengthUnit] = useState('meter');
    const [widthUnit, setwidthUnit] = useState('meter');
    const [depthUnit, setdepthUnit] = useState('meter');
    // const [volume, setvolume] = useState(null)

    const changeVal = (type, e) => {
        setvolume(null)
        switch (type) {
            case "length":
                setlength(e.target.value === '' ? '' : Math.abs(e.target.value))
                break;
            case "width":
                setwidth(e.target.value === '' ? '' : Math.abs(e.target.value))
                break;
            case "depth":
                setdepth(e.target.value === '' ? '' : Math.abs(e.target.value))
                break;
            default:
                break;
        }
    }

    const changeUnit = (type, e) => {
        setvolume(null)
        switch (type) {
            case "length":
                setlengthUnit(e.target.value)
                break;
            case "width":
                setwidthUnit(e.target.value)
                break;
            case "depth":
                setdepthUnit(e.target.value)
                break;
            default:
                break;
        }
    }

    const convertToMeters = (unit, value) => {
        switch (unit) {
            case 'meter': return value * 1;
            case 'millimeters': return value * 0.001;
            case 'centimeters': return value * 0.01;
            case 'inches': return value * 0.0254;
            case 'feet': return value * 0.3048;
            case 'yards': return value * 0.9144;
            default: return value;
        }
    }

    const calculateReqVolume = (e) => {
        e.preventDefault();     
        ReactGA.event({
            category: "Button",
            action: "Click",
            label: "CALCULATE_VOLUME"
          });
        const lengthInMeters = convertToMeters(lengthUnit, length);
        const widthInMeters = convertToMeters(widthUnit, width);
        const depthInMeters = convertToMeters(depthUnit, depth);
        const reqVol = lengthInMeters * widthInMeters * depthInMeters;
        setvolume(reqVol.toFixed(2));
        return false;
    }

    const reset = () => {
        setlength(0);
        setdepth(0);
        setwidth(0);
        setvolume(null);
        setlengthUnit('meter');
        setdepthUnit('meter');
        setwidthUnit('meter');
    }

    return (
        <div className="container calc-container pb-30 " id="concrete-calculator">
            <div className="row justify-content-center gy-4 ma-30 align-items-center">
                <div className='col-lg-12 align-items-center justify-content-center d-flex'>
                    <img src={concrete_calculator} alt="concrete-calculator" height="70px" className='wow animate__animated animate__fadeInLeft' />
                    <div className="content">

                        <h3 className='wow animate__animated animate__fadeInLeft'>CONCRETE CALCULATOR</h3>
                        <p>Enter a whole, numerical value, with no decimals, in each field to calculate</p>
                    </div>

                </div>

                <div className="col-lg-12 wow animate__fadeInLeft">
                    <form className="" onSubmit={calculateReqVolume}>
                        <div className="row gy-3">
                            <div className="col-lg-4 gy-3">
                                <div className='row gy-3'>
                                    <div className="col-md-7">
                                        <input type="number" name="length" className="form-control" value={length} placeholder="Length" required onChange={(e) => { changeVal('length', e) }} step="0.01"/> </div>
                                    <div className="col-md-5">
                                        <select className="form-select" aria-label="select unit" value={lengthUnit} onChange={(e) => { changeUnit('length', e) }}>
                                            <option value="meter" selected>meters</option>
                                            <option value="millimeters">millimeters</option>
                                            <option value="centimeters">centimeters</option>
                                            <option value="inches">inches</option>
                                            <option value="feet">feet</option>
                                            <option value="yards">yards</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}

                            <div className="col-lg-4 gy-3">
                                <div className='row gy-3'>
                                    <div className="col-md-7">
                                        <input type="number" name="width" className="form-control" value={width} placeholder="Width" required onChange={(e) => { changeVal('width', e) }} min="0" step="0.01" /> </div>
                                    <div className="col-md-5">
                                        <select className="form-select" aria-label="select unit" value={widthUnit} onChange={(e) => { changeUnit('width', e) }}>
                                            <option selected>meters</option>
                                            <option value="millimeters">millimeters</option>
                                            <option value="centimeters">centimeters</option>
                                            <option value="inches">inches</option>
                                            <option value="feet">feet</option>
                                            <option value="yards">yards</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 gy-3">
                                <div className='row gy-3'>
                                    <div className="col-md-7">
                                        <input type="number" name="depth" className="form-control" value={depth} placeholder="Depth" required onChange={(e) => { changeVal('depth', e) }} min="0"  step="0.01"/> </div>
                                    <div className="col-md-5">
                                        <select className="form-select" aria-label="select unit" value={depthUnit} onChange={(e) => { changeUnit('depth', e) }}>
                                            <option selected>meters</option>
                                            <option value="millimeters">millimeters</option>
                                            <option value="centimeters">centimeters</option>
                                            <option value="inches">inches</option>
                                            <option value="feet">feet</option>
                                            <option value="yards">yards</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 text-center">
                                {/* <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your quote request has been sent successfully. Thank you!</div> */}
                                <button type="submit"
                                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                                >
                                    Calculate
                                </button>
                                <button type="button" onClick={reset}
                                    className="btn btn-light py-md-3 px-md-5 me-3 animated slideInLeft"
                                >
                                    Reset
                                </button>
                            </div>
                            {
                                volume !== null &&
                                <div className="col-md-12 text-center">
                                    <h1 className="mb-4">
                                        {`You'll need:`}
                                        <span className="text-primary">{` ${volume}m³`}</span>
                                    </h1>
                                    <p>
                                        {`The above-mentioned values should not be used as 
an exact estimate of concrete required on site as practical site conditions vary and may need a higher or lower 
quantity depending on the quality and workmanship of centering, beam depths, reinforcement used etc.`}
                                    </p>
                                </div>

                            }
                        </div>
                    </form>
                </div>

            </div>

        </div>
    )
}




export default React.memo(ConcreteCalculator)