import React from "react"

const ConcreteSvgComponent = props => (
  <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 77.24 47.44" {...props} >
    <defs>
      <style>{".cls-1{fill:#444}"}</style>
    </defs>
    <path
      className="cls-1"
      d="M76.1 31.12h-.86v-4.69a4.94 4.94 0 00-1.34-3.12l-6.52-9.39a3.68 3.68 0 00-2.71-1.62h-8.3v-5H52.8v15.83h-3.45a122.17 122.17 0 00.65-14 3.14 3.14 0 00-1.62-2.83L33.84.35a4.55 4.55 0 00-2.45-.29L3.48 3.89a3 3 0 00-2.24 2.59L0 19.78a3.05 3.05 0 001.8 3l3.76 1.45v14.18a1.13 1.13 0 001.14 1.13h2.8a8 8 0 0015.81 1.76 8 8 0 0015.82-1.76h15.31a8 8 0 0016 0h3.66a1.14 1.14 0 001.14-1.13v-6.15a1.14 1.14 0 00-1.14-1.14zM17.52 43.75a4.34 4.34 0 114.33-4.34 4.35 4.35 0 01-4.33 4.34zm15.59 0a4.34 4.34 0 114.33-4.34 4.35 4.35 0 01-4.33 4.34zm11.66-15.46L30 29.42a3.57 3.57 0 01-.75 0L3.74 19.57 4.86 7.42l27-3.69a2.19 2.19 0 01.64 0l13.81 5.75c.02 1.94.04 11.88-1.54 18.81zm19.69 15.46a4.34 4.34 0 114.33-4.34 4.35 4.35 0 01-4.33 4.34zm5.07-18.69H57.82a.91.91 0 01-.87-.95v-7.27a.92.92 0 01.87-.95h5.58a2.23 2.23 0 011.49.67l5.18 7.23c.34.35-.07 1.27-.54 1.27z"
    />
    <path
      className="cls-1"
      d="M31.58 7L7.71 10.18 8 12.66l23.6-3.13 9.4 3.53.88-2.34-9.69-3.65a1.42 1.42 0 00-.61-.07z"
    />
  </svg>
)

export default ConcreteSvgComponent;