import React from "react"

const ScreedSvgComponent = props => (
  <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 76.88 61.03" {...props} >
    <defs>
      <style>{".cls-1{fill:#444}"}</style>
    </defs>
    <path
      className="cls-1"
      d="M32.53 61.03L35.67 59.01 26.06 53.68 22.87 55.54 32.53 61.03z"
    />
    <path
      className="cls-1"
      d="M65.53 30.99L73.73 35.73 76.88 33.7 68.72 29.1 65.53 30.99z"
    />
    <path
      className="cls-1"
      d="M68.29 23.77a2 2 0 00-1-1.71l-15.78-9.19a3.16 3.16 0 00-2.95 0l-14 8.05 1.9 2.37 13.56-7.82 14.17 8.31-46 26.55L4.06 42l22.71-13.11-1.9-2.37L1.05 40.29A2 2 0 000 42v1.4a2 2 0 001 1.71l15.79 9.19a2.93 2.93 0 001.47.38 3 3 0 001.48-.37l47.51-27.44a2 2 0 001-1.72z"
    />
    <path
      className="cls-1"
      d="M56.2 22L44 29l-3.37-2 12.21-7-2.48-1.45L35.75 27a3 3 0 00-.63-1.39L14.62 0 9.71 3.63l20 24.91v1.92L9.33 42.2l2.49 1.45 15.12-8.72 3.43 1.92-15.2 8.76 2.49 1.46 41-23.66z"
    />
  </svg>
)

export default ScreedSvgComponent
