import React, {useState} from 'react'
import ReactGA from 'react-ga4';
import imgSrc1 from '../assets/gwc-truck.webp'
import imgSrc2 from '../assets/gmn-carousal-5.webp';
import imgSrc3 from '../assets/gmn-carosal.webp'

import ContentLoader from 'react-content-loader'

const ImgPlaceHolder = () => <ContentLoader viewBox="0 0 380 70" height='100vh' 
backgroundColor="#f3f3f3"
foregroundColor="#ecebeb">
  <rect x="48" y="8" rx="3" ry="3" width="88" height="6" /> 
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" /> 
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" /> 
    <circle cx="20" cy="20" r="20" />
</ContentLoader>

const Carousal = ({showBookingModel}) => {
  const [isImageloaded, setImageloaded] = useState(false)
  const triggerAnalytics = () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "CONCRETE_CALCULATOR"
    });
  }

  return (
    <div
    id="home"
    className="carousel slide nav-section"
    data-bs-ride="carousel"
    data-interval="2000"
  >
    <div className="carousel-inner">
      <div className="carousel-item active">
        { !isImageloaded && <ImgPlaceHolder/>

        }
        <img className="w-100" style={{height: '100vh', objectFit: 'cover'}} src={imgSrc1} alt="carousal-1"  onLoad={() => {setImageloaded(true)}}/>
        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div className="p-3" style={{ maxWidth: 700 }}>
            <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown font-arial">
            GMN Concrete
            </h6>
            <h2 className="display-3 text-white mb-4 animated slideInDown text-uppercsase">
            Concreting your {" "} 
            <span className="text-primary text-uppercase">dreams</span>
            </h2>
            <button
              className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              onClick={showBookingModel}
            >
                Make a booking
            </button>
            <a href="#concrete-calculator"
              className="btn btn-light py-md-3 px-md-5 me-3 animated slideInLeft calc-button"
              onClick={triggerAnalytics}
            >
             Concrete calculator
            </a>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <img className="w-100" style={{height: '100vh', objectFit: 'cover'}} src={imgSrc2} alt="carousal-2" />
        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div className="p-3" style={{ maxWidth: 700 }}>
            <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown font-arial">
            GMN Concrete
            </h6>
            <h1 className="display-3 text-white mb-4 animated slideInDown font-homo">
            Homogenous, dependable and guaranteed {" "}
            <span className="text-primary text-uppercase">quality</span>

            </h1>
            <button
              className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              onClick={showBookingModel}              
            >
                Make a booking
            </button>
            <a href="#concrete-calculator"
              className="btn btn-light py-md-3 px-md-5 me-3 animated slideInLeft calc-button"
              onClick={triggerAnalytics}
            >
             Concrete calculator
            </a>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <img className="w-100" style={{height: '100vh', objectFit: 'cover'}} src={imgSrc3} alt="carousal-2" />
        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div className="p-3" style={{ maxWidth: 700 }}>
            <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown font-arial">
            GMN Concrete
            </h6>
            <h1 className="display-3 text-white mb-4 animated slideInDown">
            <span className="text-primary text-uppercase">Wide range</span>

            {" "} of concrete mixes to choose from
            </h1>
            <button
              className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
              onClick={showBookingModel}
              
            >
                Make a booking
            </button>
            <a href="#concrete-calculator"
              className="btn btn-light py-md-3 px-md-5 me-3 animated slideInLeft calc-button"
              onClick={triggerAnalytics}
            >
             Concrete calculator
            </a>
          </div>
        </div>
      </div>
    </div>
    <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#home"
      data-bs-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#home"
      data-bs-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
  )
}

export default Carousal