import React, { useState, useEffect } from "react";

import "../App.scss";



function scrollToTop(smooth = false) {
  if (smooth) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    document.documentElement.scrollTop = 0;
  }
}

const ContactButtons = ({
  top = 20,
  className = "",
  color = "black",
  smooth = false,
  component = "",
  viewBox = "0 0 256 256",
  
  width = "28",
  height = "28",
  ...props
}) => {

  return (
    <span className="row">
        <a href="tel:080 6984-5252" className="btn btn-lg btn-lg-square btn-primary floating-contacts rounded-circle" width="16" height="16" >
            <strong> <i className="fa fa-phone-alt mx-2" /> </strong>
        </a>
        <a href="https://api.whatsapp.com/send?phone=918069845254" className="btn btn-lg  btn-primary btn-lg-square floating-contacts rounded-circle" style={{bottom: '145px' }}>
            <strong><i className="fab fa-whatsapp green-color mx-1" /></strong>
        </a>
        
    </span>
  );
};

export default ContactButtons;