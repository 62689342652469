import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image1 from '../assets/gmn-carousal-1.webp';
import Image5 from '../assets/gmn-carousal-2.webp';
import Image2 from '../assets/gmn-carousal-3.webp';
import Image4 from '../assets/gmn-carousal-4.webp';
import Image3 from '../assets/gmn-carousal-5.webp';
import Image6 from '../assets/gmn-carosal.webp'



const imageArray = [Image1, Image2, Image3, Image4, Image5, Image6]
const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 3
        },
        1000: {
            items: 3
        }
    }
};

export const ImageGallery = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/gallery", title: "Gallery" });
    }, [])
    return (
        <div className='container-xxl py-5 nav-section' id="gallery" >
            <div className="container">
                <div
                    className="text-center wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.1s"
                >
                    <p className="section-title text-center text-primary text-uppercase">
                        Explore more
                    </p>
                    <h1 className="mb-5">
                        Image
                        <span className="text-primary text-uppercase"> Gallery</span>
                    </h1>
                </div>
                <div className='row'>
                    <OwlCarousel className="owl-theme" {...options}>
                        {
                            imageArray.map((img) => {
                                return <div className="item set-bg" style={{ backgroundImage: `url(${img})` }} >

                                </div>
                            })
                        }

                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}
