import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Stepper } from 'react-form-stepper';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import '../App.scss';
import ConcreteSvgComponent from './SVGComponents/Concrete';
import ScreedSvgComponent from './SVGComponents/Screed';
import ConcreteCalculator from './ConcreteCalculator';
import DatePicker from 'react-date-picker';

export const BookingModel = (props) => {
    const [activeStep, setactiveStep] = useState(0);
    const [stepOneselection, setStepOneselection] = useState(null);
    const [selectedGrade, setselectedGrade] = useState('M10');
    const [selectedConcreteType, setconcreteType] = useState('Normal')
    const [isPumpRequired, setisPumpRequired] = useState(false);
    const [showCalculator, setshowCalculator] = useState(false);
    const [concreteAmount, setconcreteAmount] = useState(null);
    const [fullName, setfullName] = useState('');
    const [Phone, setPhone] = useState('');
    const [postCode, setpostCode] = useState('');
    const [email, setemail] = useState('');
    const [volume, setvolume] = useState(null)
    const [date, setDate] = useState(new Date());
    const [formErrors, setformErrors] = useState({
        nameError: '',
        emailError: '',
        phoneError: '',
        postcodeError: ''
    })
    const concreteGradeOptions = [
        'M10', 'M15', 'M20', 'M25', 'M30', 'M35', `Don't know`];
    const concreteTypes = ['Normal', 'Waterproof'];
    const nextClickHandler = () => {
        // To be added later
        if (activeStep !== 4) {
            setactiveStep(activeStep + 1);
        }
        else {
            //last step
            makeBooking();
           
        }
    }

    const makeBooking = async () => {
        const bookingDetails = {
            concrete_or_Screed : stepOneselection === 'concrete' ? 'Concrete': 'Screed',
            concrete_grade: selectedGrade,
            concrete_type: selectedConcreteType,
            amount_of_concrete_required: concreteAmount,
            is_pump_required: isPumpRequired,
            fullName,
            postCode,
            email,
            date ,
            phone_number: Phone                
        }
        const hasError = validateInput();
        if(hasError) return
        const response = await fetch('https://formsubmit.co/ajax/59077dba8be1ce7f991dfec17e93c3a6', {
            method: 'POST',
            headers: {
              Accept: 'application.json',
              'Content-Type': 'application/json'
            },
            body : JSON.stringify(bookingDetails)
          })
          if (response.status === 200) {
            props.setToast(true)
            props.onHide();
            clearInputs();
          }

    }

    const clearInputs = () => {
        setactiveStep(0);
        setStepOneselection(null);
        selectedGrade('M10')
        setconcreteType('Normal');
        setisPumpRequired(false);
        showCalculator(false);
        setconcreteAmount(null);
        setfullName('');
        setPhone('')
        setemail('')
        setpostCode('')
        setDate(new Date())
        setformErrors({
            nameError: '',
            emailError: '',
            phoneError: '',
            postcodeError: ''
        })

    }

    const onSelectGrade = (eventKey) => {
        setselectedGrade(eventKey)
    }
    const onSelectType = (eventKey) => {
        setconcreteType(eventKey)
    }

    const setPumpRequired = (status) => {
        setisPumpRequired(status)
    }

    const prevClickHandler = () => {
        // To be added later
        if (activeStep !== 0) {
            setactiveStep(activeStep - 1);
        }
        else {
            props.onHide()
        }
    }


    const checkiSDisabled = () => {
        switch (activeStep) {
            case 0:
                if (stepOneselection) {
                    return false
                }
                return true;

            default:
                return false;
        }
    }

    const chooseProduct = (item) => {
        setStepOneselection(item);
    }

    const stepFiveInputHandler = (type, event) => {
        setformErrors({
            nameError: '',
            emailError: '',
            phoneError: ''
        });
        switch (type) {
            case 'name':
                setfullName(event.target.value);
                break;
            case 'phone':
                if(event.target.value.length <= 10 )
                setPhone(event.target.value);
                break;
            case 'email':
                setemail(event.target.value);
                break;
            case 'postcode':
                if(event.target.value.length <= 6 )
                setpostCode(event.target.value);
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        if(activeStep === 2) {
            setconcreteAmount(volume);
        }
    }, [volume])

    const validateInput = () => {
        const fieldValidationErrors = {...formErrors}
        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.emailError = emailValid ? '' : ' is invalid';
        if(Phone.length < 10) {
            fieldValidationErrors.phoneError = 'is invalid'
        }
        if(fullName === '') {
            fieldValidationErrors.nameError = 'is invalid'
        }
        if(postCode.length < 6) {
            fieldValidationErrors.postcodeError = 'is invalid'
        }
        setformErrors(fieldValidationErrors)
        if(fieldValidationErrors.emailError || fieldValidationErrors.nameError || fieldValidationErrors.phoneError) return true;
        else return false;
    }
    




    const StepOne = () => {
        return <div className='step-1 container'>
            <h3>Do you need concrete or screed?</h3>
            <div className='step-1-cards row gx-4'>
                <Card style={{ width: '18rem', height: '10rem', cursor: 'pointer' }} className={stepOneselection === 'concrete' ? "bordershadow selected col-lg-6" : 'bordershadow col-lg-6'} onClick={() => chooseProduct('concrete')}>
                    <Card.Body className='card-body'>
                        <ConcreteSvgComponent height="4rem" />
                        <Card.Title style={{ display: 'flex', justifyContent: "center" }}>Concrete</Card.Title>
                    </Card.Body>
                </Card>
                <Card style={{ width: '18rem', height: '10rem', cursor: 'pointer' }} className={stepOneselection === 'screed' ? "bordershadow selected col-lg-6" : 'bordershadow col-lg-6'} onClick={() => chooseProduct('screed')}>
                    <Card.Body className='card-body'>
                        <ScreedSvgComponent height="4rem" />
                        <Card.Title style={{ display: 'flex', justifyContent: "center" }}>Screed</Card.Title>
                    </Card.Body>
                </Card>
            </div>

        </div>
    }


    const StepTwo = () => {
        return <div className='container' style={{ width: '83%', marginTop: '20px' }}>
            <div className='row'>
                <div className="col-lg-6">
                    What grade of concrete do you need?
                    <i className="fa fas fa-info-circle" style={{ color: '#5e5959', marginLeft: '5px' }} />
                </div>
                <div className="col-lg-6">
                    <Dropdown className="d-inline mx-2" defaultValue="M10" onSelect={onSelectGrade}>
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                            {selectedGrade}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                concreteGradeOptions.map((option) => (
                                    <Dropdown.Item value={option} eventKey={option}>{option}</Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='row mt-3'>
                <div className="col-lg-6">
                    What kind of concrete would you like?
                </div>
                <div className="col-lg-6">
                    <Dropdown className="d-inline mx-2" defaultValue="Normal" onSelect={onSelectType}>
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                            {selectedConcreteType}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                concreteTypes.map((option) => (
                                    <Dropdown.Item value={option} eventKey={option}>{option}</Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

        </div>
    }

    const StepThree = () => {
        const onChange = (e) => { setconcreteAmount(e.target.value) }
        return <div className='container ma-2' style={{ width: '90%' }}>
            <div className='row justify-content-center mb-3 align-items-center' style={{ width: '100%' }}>
                <span className='col-lg-4'>How much concrete do you need (m³)?</span>
                <Button className='col-lg-8' style={{ width: '14rem' }} variant="outline-primary" onClick={() => { setshowCalculator(true) }}>Calculate Amount</Button>{' '}

            </div>
            {
                showCalculator &&
                <div className='row'>
                    <ConcreteCalculator volume={volume} setvolume={setvolume} />
                </div>

            }
            <div className='row mt-5 justify-content-center'>
                <input type="number" name="amount" className="form-control boxshadow-default " value={concreteAmount} placeholder="Amount (m³) *" required onChange={(e) => { setconcreteAmount(e.target.value) }} min="0" style={{ width: '50%' }} />
            </div>

        </div>
    }

    const StepFour = () => {
        return <div className='step-1 container'>
            <h3>Do you need a Pump for this project?</h3>
            <div className='step-1-cards row gx-4'>
                <Card style={{ width: '18rem', height: '10rem', cursor: 'pointer' }} className={isPumpRequired ? "bordershadow selected col-lg-6" : 'bordershadow col-lg-6'} onClick={() => setPumpRequired(true)}>
                    <Card.Body className='card-body'>
                        <i className="fa fas fa-check fa-2x text-dark" />
                        <Card.Title style={{ display: 'flex', justifyContent: "center" }}>Yes please</Card.Title>
                    </Card.Body>
                </Card>
                <Card style={{ width: '18rem', height: '10rem', cursor: 'pointer' }} className={!isPumpRequired ? "bordershadow selected col-lg-6" : 'bordershadow col-lg-6'} onClick={() => setPumpRequired(false)}>
                    <Card.Body className='card-body'>
                        <i className="fa fas fa-times fa-2x text-dark" />
                        <Card.Title style={{ display: 'flex', justifyContent: "center" }}>No, Thanks</Card.Title>
                    </Card.Body>
                </Card>
            </div>
        </div>
    }

    const StepFive = () => {
        return <div className='container'>
            <div className='row '>
                <div className='col-lg-12 justify-content-center d-flex '>
                    <div className='font-weight-bold'>
                        When would you like your delivery?
                    </div>
                </div>
                <div className='col-lg-12  justify-content-center d-flex mt-3'>
                    <DatePicker onChange={setDate} value={date} />
                </div>
                <div className='row' style={{ paddingRight: '0', marginTop: '5px' }}>
                    <div className='col-lg-12 justify-content-center d-flex '>
                        <div className='font-weight-bold'>
                            Your Details
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div >
                            <label for="fullName" className='margin-top-bottom'>Name</label>
                            <input type="text" key="fullName" className={formErrors.nameError? 'hasError form-control' : "form-control"} placeholder="Full Name" value={fullName} onChange={(event) => { stepFiveInputHandler('name', event) }} />
                        </div>
                        <div>
                            <label for="Name" className='margin-top-bottom'>Phone</label>
                            <input type="number" key="Phone" className={formErrors.phoneError? 'hasError form-control' : "form-control"} placeholder="Full phone number" value={Phone} onChange={stepFiveInputHandler.bind(this, 'phone')} />
                        </div>
                    </div>
                    <div className='col-lg-6' style={{ paddingRight: '0' }}>
                        <div>
                            <label for="Name" className='margin-top-bottom'>Email</label>
                            {/* <input type="email" className="form-control" placeholder="Email address" value={email} onChange={stepFiveInputHandler.bind(this, 'email')} /> */}
                            <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => {
                                setemail(e.target.value)
                            }} className={formErrors.emailError ? 'hasError' : ''} />
                        </div>

                        <div>
                            <label for="Name" className='margin-top-bottom'>Postcode</label>
                            <input type="number" className={formErrors.postcodeError? 'hasError form-control' : "form-control"}  placeholder="Postcode" maxLength="6" value={postCode} onChange={stepFiveInputHandler.bind(this, 'postcode')} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }


    useEffect(() => {
        setshowCalculator(false);
    }, [activeStep])

    const closeFunc  = () => {
        props.onHide();
        clearInputs();
    }


    return (
        <Modal
            {...props}
            size={activeStep === 2 ? 'xl' : 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            dialogClassName="modal-50"
            onHide={closeFunc}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Booking Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stepper
                    steps={[{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }, { label: 'Step 4' }, { label: 'Step 5' }]}
                    activeStep={activeStep}
                    styleConfig={{
                        activeBgColor: '#fea116',
                        completedBgColor: '#009688'
                    }}
                />
                {
                    activeStep === 0 && <StepOne />

                }
                {
                    activeStep === 1 && <StepTwo />
                }
                {
                    activeStep === 2 && <>
                        {StepThree()}
                    </>

                }
                {
                    activeStep === 3 && <StepFour />

                }
                {
                    activeStep === 4 && <>{
                        StepFive()
                    }
                    </>

                }

            </Modal.Body>
            <Modal.Footer className='custom-modal-footer d-flex justify-content-between'>
                <div className=''>
                    <div>Need Help ?</div>
                    <div className='align-left d-flex'>
                        <a href="https://api.whatsapp.com/send?phone=918069845254" className="text-decoration-none text-dark">
                            <i className="fab fa-whatsapp green-color mx-1" />
                        </a>
                        <a href="tel:080 6984-5252" className="text-decoration-none text-dark">
                            <i className="fa fa-phone-alt mx-2" />
                        </a>
                    </div>
                </div>
                <div>
                    <Button onClick={prevClickHandler} className="btn btn-light text-dark">{activeStep === 0 ? 'Close' : 'Previous'}</Button>
                    <Button onClick={nextClickHandler} disabled={checkiSDisabled()}>{activeStep !== 4 ? 'Next' : 'Send'}</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
