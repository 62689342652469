import "./App.scss";
import "animate.css";
import { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import ReactGA from "react-ga4";
import ToastContainer from 'react-bootstrap/ToastContainer';
import Carousal from "./components/Carousal";
import { Header } from "./components/Header";
import ConcreteCalculator from "./components/ConcreteCalculator";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
// eslint-disable-next-line no-unused-vars
import WOW from "wowjs";
import { Footer } from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import ContactButtons from "./components/ContactButtons";
import { BookingModel } from "./components/BookingModel";
import RequestQuote from "./components/RequestQuote";
import Projects from "./components/Projects";
import { ImageGallery } from "./components/ImageGallery";
import { useEventHandler } from "./hooks/useEventHandler";

function App() {
  const measurementID = "G-1LG51WJWGC";
  ReactGA.initialize(measurementID);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
    new WOW.WOW().init();
    setTimeout(() => {
      scrollToHash();
    }, 100);
    if (window.location.href.indexOf("booking") > -1) {
      setModalShow(true);
    }
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const [volume, setvolume] = useState(null);
  const [showToast, setToast] = useState(false);
  const showBookingModel = () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "MAKE_A_BOOKING"
    });
    window.history.replaceState(null, null, "booking");
    setModalShow(true);
  };

  const Scroll = () => {
    const sections = document.querySelectorAll(".nav-section");
    const nav = document.querySelectorAll(".navbar-collapse ul li ");
    // change active nav link based on scroll
    var current = "";
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (window.scrollY >= sectionTop - 300) {
        current = section.getAttribute("id");
      }
    });
    if (!window.location.hash.includes(current)) {
      // window.location.hash = `#${current}`
      window.history.replaceState(null, null, '#' + current);
    }

    nav.forEach((li) => {
      li.classList.remove("active");
      if (li.classList.contains(current)) {
        li.classList.add("active");
      }
    });
  };

  useEventHandler("scroll", Scroll);
  //call custom hook for scroll event listener

  const scrollToHash = () => {
    if (window.location.hash) {
      const hash = window.location.hash;
      const target = document.querySelector(hash);
      const headerOffset = 114;
      const elementPosition = target.offsetTop;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  return (
    <div className="App">
      <div className="container bg-white p-0">
        <Header />
        {/* Carousel STart */}
        <div className="row p-0 mb-5">
          <Carousal showBookingModel={showBookingModel} />

          <AboutUs />
          <ConcreteCalculator volume={volume} setvolume={setvolume} />
        </div>
        {/* Carousel End */}
        {/* Service Start */}
        <Services />
        <Projects />
        <ImageGallery />
        <RequestQuote />
        {/* Service End */}

        <Footer />
        {/* Back to Top */}
        <ContactButtons />
        <ScrollToTop />
      </div>
      <BookingModel show={modalShow} onHide={() => {
         window.location.href = "/";
        setModalShow(false)
      }} setToast={setToast} />
      <ToastContainer className="p-3" position='top-end'>
        <Toast onClose={() => setToast(false)} show={showToast} animation={true} delay={3000} autohide>
          <Toast.Header>Response Recorded</Toast.Header>
          <Toast.Body>Thank you for reaching out. Our team will be in touch with you shortly</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default App;
