import React, { useState, useEffect } from "react";

import "../App.scss";



function scrollToTop(smooth = false) {
  if (smooth) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    document.documentElement.scrollTop = 0;
  }
}

const ScrollToTop = ({
  top = 20,
  className = "",
  color = "black",
  smooth = false,
  component = "",
  viewBox = "0 0 256 256",
  
  width = "28",
  height = "28",
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setVisible(document.documentElement.scrollTop >= top);
    };
    onScroll();
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [top]);

  return (
    <>
      {visible && (
        <button
          className={`btn btn-lg btn-primary btn-lg-square back-to-top rounded-circle`}
          onClick={() => scrollToTop(smooth)}
          aria-label="Scroll to top"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
            
          {component || (
            // <svg width={width} height={height} fill={color} viewBox={viewBox}>
            //   <path d={svgPath} />
            // </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
            </svg>
          )}
        </button>
      )}
    </>
  );
};

export default ScrollToTop;