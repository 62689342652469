import React from 'react'

export const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container-xxl pb-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-5">
            <h6 className="section-title text-start text-primary text-uppercase mb-4">
              Contact
            </h6>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3" />
              Sy No. 138. Gollahalli Post,
              Byrasettihalli, Nelamangala Taluk,
              Bangalore Rural - 562123
            </p>
            <p className="mb-2">
            <a href="tel:080 6984-5252" className="footer-link">
            <i className="fa fa-phone-alt me-3" />
              080 6984-5252
              </a>
            </p>
            <p className="mb-2">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a href="mailto:info@gmnconcrete.com" className="footer-link">
                <i className="fa fa-envelope me-3" />
                info@gmnconcrete.com
              </a>
            </p>
            <p className="mb-2">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a href="https://api.whatsapp.com/send?phone=918069845254" className="footer-link">
                <i className="fab fa-whatsapp green-color me-3" />
                +91 80 69845254 
              </a>
            </p>
            {/* <div className="d-flex pt-2">
            <a className="btn btn-outline-light btn-social" href="">
              <i className="fab fa-facebook-f" />
            </a>
            <a className="btn btn-outline-light btn-social" href="">
              <i className="fab fa-youtube" />
            </a>
            <a className="btn btn-outline-light btn-social" href="">
              <i className="fab fa-linkedin-in" />
            </a>
          </div> */}
          </div>
        </div>
        <hr />
        <div className='row'>
        
        </div>
        <div className='d-flex g-5 justify-content-between'>
          <div className='text-primary'>Designed & Developed by <a className='text-decoration-none text-primary' href="https://itssach.in">FullPowerTec</a></div>
          <p>© GMN Concrete. All Rights Reserved</p>
        </div>
      </div>
    </div>
  )
}
