import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Badge from "react-bootstrap/Badge";
import Image1 from '../assets/client1.jpeg';
import Image2 from '../assets/client2.jpeg';
import Image3 from '../assets/client3.jpeg';
import Image4 from '../assets/client4.jpeg';
import Image5 from '../assets/client5.jpeg';
import Image6 from '../assets/client6.jpeg';
import Image7 from '../assets/client7.jpeg';
import Image8 from '../assets/client8.jpeg';
import Image9 from '../assets/client9.jpeg';
import Image10 from '../assets/client10.jpeg';


const Projects = () => {
  const [nextSlide, setnextSlide] = useState(0)
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/projects", title: "Projects" });
  }, [])
  // const projects = ['RKIPL Bridge', 'SV Constructions Highway', 'HK Suresh House', 'Star Constructions', 'GMN Warehouse', 'UVCE, KR Circle', 'Sequent Electricals', 'Sequent Electricals']
  const projects = [{
    img: Image1,
    addressLine1: "3 & 4 BHK Luxury Apartments",
    addressLine2: "Yeshwanthpur, Bangalore",
    website: "https://cardinalone.in "
  }, {
    img: Image2,
    addressLine1: "1, 2 & 3 BHK Smart Homes",
    addressLine2: "Doddabidarakallu, Bangalore",
    website: "https://subrambuilders.in/"
  }, {
    img: Image3,
    addressLine1: "Naturotherapy Hospital",
    addressLine2: "Ramanagara District, Solur",
    website: "https://mrrnaturecure.com"
  }, {
    img: Image4,
    addressLine1: "Commercial Building",
    addressLine2: "Rajajinagar, Bangalore",
    website: " http://sequentelectricals.com"
  },
  {
    img: Image5,
    addressLine1: "Residential & Commercial"    ,
    addressLine2: "RMV/Yelahanka, Bangalore",
    website: "http://dalavaikashi.github.io/testsite/gh-pages"
  },
  {
    img: Image6,
    addressLine1: "1, 2 & 3 BHK Smart Homes"    ,
    addressLine2: "Doddabidarakallu, Bangalore",
    website: "https://united-developers.in"
  },
  {
    img: Image7,
    addressLine1: "3 & 4 BHK Luxury Villas"    ,
    addressLine2: "Thirumenahalli, Bangalore North",
    website: "http://www.hindujaholdings.com"
  },
  {
    img: Image8,
    addressLine1: "Industrial Building"    ,
    addressLine2: "Peenya, Bangalore",
    website: "http://www.hindujaholdings.com"
  },
  {
    img: Image9,
    addressLine1: "Residential Projects"    ,
    addressLine2: "West Bangalore",
    website: "https://www.kailashdev.com"
  },
  {
    img: Image10,
    addressLine1: "Nelamangala Doddabalapur Highway"    ,
    addressLine2: "Nelamangala, Bangalore Rural",
    website: "https://rkipl.in"
  }
]

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const isdividerRequired = (index) => {
    const deviceType = getDeviceType();
    switch (deviceType) {
      case "desktop":
        return ((nextSlide + 4) -1 !== index) 
      case "tablet":
        return !((index + 1) % Math.min(2, projects.length-(index))) == 0
      case "mobile":
        return false
      default:
        return !((index + 1) % Math.min(4, projects.length-(index))) == 0
    }
  }

  const doSpeicalThing= (currentSlide, nextSlide)=> {
    setnextSlide(nextSlide);
    setCurrent(currentSlide);
  }


  function renderProjects() {
    return <Carousel containerClass="project-card-container" 
    itemClass='card-box-shadow'
    responsive={responsive} renderButtonGroupOutside={true}>{
      projects?.map((project, index) => {
        const showDivider = isdividerRequired(index);
        console.log("showDivider", showDivider)
        return <div style={{display: 'flex'}}>
          <div className='project-card'>
            {/* <Badge className=" font-14" bg="secondary">{project}</Badge> */}
            <a target="_blank" href={project.website} >
            <img className="project-img" src={project.img} alt="project" style={{ objectFit: 'contain'}}/>
            </a>
            <p>{project.addressLine1}</p>
            <p>{project.addressLine2}</p>
          </div>
        </div>

      })
    }
    </Carousel>
  }



  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


  return (
    <div className="container-xxl py-5 nav-section" id="projects">
      <div className="container">
        <div
          className="text-center wow animate__animated animate__fadeInUp"
          data-wow-delay="0.1s"
        >
          <p className="section-title text-center text-primary text-uppercase">
            WHO WE'VE EMPOWERED
          </p>
          <h1 className="mb-5">
            Our{" "}
            <span className="text-primary text-uppercase">Clientele</span>

            <div className='row'>{renderProjects()}</div>
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Projects