import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import logo from '../assets/LogoHorizontal.webp'
import mobileLogo from '../assets/logo.webp';
import Scrollspy from 'react-scrollspy'

export const Header = () => {
  const [navBackground, setNavBackground] = useState('appBarTransparent')
  const mediaMatch = window.matchMedia('(max-width: 768px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const navRef = React.useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 250
      if (show) {
        setNavBackground('appBarSolid')
      } else {
        setNavBackground('appBarTransparent')
      }
    }
    document.addEventListener('scroll', handleScroll)
    scrollToSection();
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToSection = () => {
    document
      .querySelectorAll('.navbar-nav a[href^="#"]')
      .forEach(trigger => {
        trigger.onclick = function (e) {
          e.preventDefault();
          let hash = this.getAttribute('href');
          let target = document.querySelector(hash);
          let headerOffset = 114;
          let elementPosition = target.offsetTop;
          let offsetPosition = elementPosition - headerOffset;
          // window.location.hash = hash;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        };
      });
  }

  const triggerAnalytics = (label) => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: label
    });
  }
  return (
    <div className="row gx-0 sticky bg-light">
      {/* <div className="col-lg-5 bg-dark d-none d-lg-block " style={{ opacity: navBackground === 'appBarTransparent' && !matches ? '0.4' : 1 }}></div> */}
      <div className="col-lg-5  d-none d-lg-block ">

        <a
          href="#ss"
          className="navbar-brand w-100 h-100 m-0 d-flex align-items-center justify-content-flex-start"
        >
          <img src={logo} alt="logo" style={{ height: '65px' }} />
          {/* <h1 className="m-0 text-primary text-uppercase font-arial">GMN Concrete</h1> */}
        </a>
      </div>
      <div className="col-lg-7">
        <nav className="navbar navbar-expand-lg  navbar-dark p-3 p-lg-0 bg-light" style={{ height: '100%' }}>
          <a href="#ww" className="navbar-brand  d-lg-none flex-container">
            <img src={logo} alt="logo" style={{ height: '30px' }} />
            {/* <h2 className="m-0 text-primary text-uppercase font-arial">GMN Concrete</h2> */}
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarCollapse"
          >
            <ul className="d-flex navbar-nav mr-auto py-0" >
              {/* <Scrollspy items={['home', 'aboutus', 'services', 'contact','projects','gallery']} currentClassName="active" className='navbar-nav mr-auto py-0' offset={-114}> */}
              <li className='active home'> 
                <a href="#home" className="nav-item nav-link" onClick={() => triggerAnalytics("NAV_HOME")}>
                <strong>Home</strong>
              </a></li>
              <li className='aboutus'>
                <a href="#aboutus" className="nav-item nav-link" onClick={() => triggerAnalytics("NAVBAR_ABOUT")}>
                  <strong>About</strong>
                </a>
              </li>
              <li className='services'>
                <a href="#services" className="nav-item nav-link" onClick={() => triggerAnalytics("NAVBAR_COMPETENCIES")}>
                  <strong>Competencies</strong>
                </a>
              </li>
              <li className='projects'>
                <a href="#projects" className="nav-item nav-link" onClick={() => triggerAnalytics("NAVBAR_PROJECTS")}>
                  <strong>Projects</strong>
                </a>
              </li>
              <li className='gallery'>
                <a href="#gallery" className="nav-item nav-link" onClick={() => triggerAnalytics("NAVBAR_GALLERY")}>
                  <strong>Gallery</strong>
                </a>
              </li>
              <li className='contact'>
                <a href="#contact" className="nav-item nav-link" onClick={() => triggerAnalytics("NAVBAR_REQUEST_QUOTE")}>
                  <strong>Contact</strong>
                </a>
              </li>
            </ul>
            {/* </Scrollspy> */}
          </div>
        </nav>
      </div>
    </div>
  )
}
